<template>
  <div class="container-fluid pb-5">
    <div class="page-header mb-1">
      <strong>ข้อมูลสมาชิก</strong>
    </div>
    <div class="row">
      <div class="col-xl-4">
        <MemberProfile />
      </div>
      <div class="col-xl-8">
        <MemberReports />
      </div>
    </div>
  </div>
</template>
<script>
import MemberProfile from '@/views/account-report/components/MemberProfile'
import MemberReports from '@/views/account-report/components/MemberReports'

export default {
  name: 'ManageAccountMember',
  components: {
    MemberProfile,
    MemberReports
  }
}
</script>
