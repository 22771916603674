<template>
  <div>
    <DateFilter :with-market-id="false" :defaultMarketIds="[]" @submit="search" />

    <div v-if="dateText.start===dateText.end">ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span></div>
    <div v-else>
      ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
      ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
      <span> ถึง
        <span class="text-primary">{{dateText.end}}</span>
      </span>
    </div>

    <div>
      <div class="card mb-0">
        <table class="table table-primary table-sm mb-0 table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th width="60">ลำดับ</th>
              <th width="180">วันที่</th>
              <th width="120">ชื่อผู้ใช้</th>
              <th width="200">ชื่อ-นามสกุล</th>
              <th width="140">IP</th>
              <th>อุปกรณ์</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id">
              <td class="text-center">{{index+1}}</td>
              <td class="text-center">{{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}</td>
              <td class="text-center">{{item.account.username}}</td>
              <td class="text-center">{{item.account.fullname}}</td>
              <td class="text-center">{{item.client.ip}}</td>
              <td class="text-left text-secondary"><small>{{item.client.userAgent}}</small></td>
            </tr>
          </tbody>
          <tbody v-if="!items.length && !isLoading">
            <tr>
              <td colspan="6" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import ReportService from '@/services/ReportService'
import moment from '@/helpers/moment'
import DateFilter from '@/views/win-lose-report/components/DateFilter'

import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'AccountLogReport',
  props: ['accountId'],
  components: {
    DateFilter
  },
  data() {
    return {
      data: null,
      isLoading: false,
      filters: {
        accountId: null,
        startDate: null,
        endDate: null
      }
    }
  },
  computed: {
    dateText() {
      return {
        start: moment(this.filters.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.filters.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      return moment(this.filters.endDate).diff(this.filters.startDate, 'd') + 1
    },
    items() {
      if(!this.data)
        return []

      return this.data.items
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        accountId: this.accountId,
        startDate: filters.date.start,
        endDate: filters.date.end
      }

      this.getReports()
    },
    getReports() {
      this.isLoading = true
      ReportService.getAccountLogs(this.filters)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        font-weight: normal;
        font-size: 90%;
        text-align: center;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
}
</style>
