<template>
  <div class="member-profile card">
    <div class="card-body">
      <div class="text-center">
        <i class="far fa-5x fa-user-circle text-info"></i>
      </div>
      <div class="text-center mt-3 text-primary">{{fullname}}</div>
      <div class="text-center mt-1">
        ยอดเงิน <span class="text-success pl-2" style="font-size: 140%; font-weight: 500;">฿{{balance | amountFormat(2, '0.00')}}</span>
      </div>
      <div v-if="canManageAccount" class="text-center mt-1">
        <button :disabled="!permTrans" class="btn btn-sm btn-success m-1" @click="deposit">เติม</button>
        <button :disabled="!permTrans" class="btn btn-sm btn-danger m-1" @click="withdraw">ถอน</button>
        <button :disabled="!permSetting" class="btn btn-sm btn-info m-1" @click="changePassword">เปลี่ยนรหัสผ่าน</button>
        <button :disabled="!permBlock" class="btn btn-sm btn-secondary m-1" @click="blockAccount">ระงับ</button>
      </div>
      <hr>
      <div>
        <h5>ข้อมูลติดต่อ</h5>
        <div class="row mt-2">
          <div class="col">ชื่อผู้ใช้</div>
          <div class="col text-primary">{{username}}</div>
        </div>
        <div class="row mt-2">
          <div class="col">ระดับสมาชิก</div>
          <div class="col text-primary"><AccountRole :role="role" /> <small v-if="accountLevel" class="text-secondary">({{accountLevel}})</small></div>
        </div>
        <div class="row mt-2">
          <div class="col">โทรศัพท์</div>
          <div class="col text-primary">{{tel}}</div>
        </div>
        <div class="row mt-2">
          <div class="col">Line ID</div>
          <div class="col text-primary">{{lineId}}</div>
        </div>
      </div>
      <div v-if="canManageAccount" class="text-center mt-4">
        <button :disabled="!permSetting" class="btn btn-sm btn-outline-warning" @click="editProfile()">แก้ไขข้อมูลส่วนตัว</button>
      </div>
    </div>

    <ChangePasswordModal :data="changePasswordItem" :is-show="isShowPasswordModal" @close="modalClosed" />
    <EditProfileModal :data="editItem" :is-show="isShowEditModal" @close="modalClosed" />
    <BlockAccountModal :data="blockItem" :is-show="isShowBlockModal" @close="modalClosed" />
    <DepositModal :data="depositItem" :is-show="isShowDepositModal" @close="modalClosed" />
    <WithdrawModal :data="withdrawItem" :is-show="isShowWithdrawModal" @close="modalClosed" />
  </div>
</template>
<script>
import ManageAccountService from '@/services/ManageAccountService'

import ChangePasswordModal from '@/views/manage-account/components/ChangePasswordModal'
import EditProfileModal from '@/views/manage-account/components/EditProfileModal'
import BlockAccountModal from '@/views/manage-account/components/BlockAccountModal'
import DepositModal from '@/views/manage-account/components/DepositModal'
import WithdrawModal from '@/views/manage-account/components/WithdrawModal'

import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'MemberProfile',
  components: {
    ChangePasswordModal,
    EditProfileModal,
    BlockAccountModal,
    DepositModal,
    WithdrawModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      /**
       * เปลี่ยรหัสผ่าน
       */
      isShowPasswordModal: false,
      changePasswordItem: {
        _id: null,
        username: null,
        nPassword: null,
        cPassword: null
      },
      /**
       * แก้ไขข้อมูลส่วนตัว
       */
      isShowEditModal: false,
      editItem: {
        _id: null,
        username: null,
        firstName: null,
        lastName: null,
        tel: null,
        lineId: null
      },
      /**
       * ระงับการใช้งาน
       */
      isShowBlockModal: false,
      blockItem: {
        _id: null,
        username: null,
        blockNote: null
      },
      /**
       * เติมเงิน
       * @type {Object}
       */
      isShowDepositModal: false,
      depositItem: null,
      /**
       * ถอนเงิน
       * @type {Object}
       */
      isShowWithdrawModal: false,
      withdrawItem: null
    }
  },
  computed: {
    agentLevels() {
      return this.$store.state.accountLevels
    },
    currentAccountId() {
      return this.$store.state.account._id
    },
    accountId() {
      return this.$route.params?.accountId
    },
    role() {
      if(!this.data)
        return '-'

      return this.data?.account.role
    },
    accountLevel() {
      if(!this.data)
        return ''

      const level = this.agentLevels.find((level)=>{
        return level._id === this.data?.account?.levelId
      })

      return level?.levelName
    },
    username() {
      if(!this.data)
        return '-'

      return this.data?.user.username
    },
    fullname() {
      if(!this.data)
        return '-'

      return `${this.data?.user.firstName} ${this.data?.user.lastName}`.trim()
    },
    tel() {
      if(!this.data)
        return '-'

      return this.data?.user.tel || '-'
    },
    lineId() {
      if(!this.data)
        return '-'

      return this.data?.user.lineId || '-'
    },
    canManageAccount() {
      return this.currentAccountId === this.data?.account?.createdBy
    },
    balance() {
      return this.data?.account?.balance || 0
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permTrans() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.FinanceMember === 'w'
    },
    permBlock() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.BlockMember === 'w'
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.MemberSetting === 'w'
    }
  },
  methods: {
    loadAccount() {
      ManageAccountService.getAccount(this.accountId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    modalClosed(needReload) {
      this.isShowModal = false
      this.isShowEditModal = false
      this.isShowPasswordModal = false
      this.isShowBlockModal = false
      this.isShowDepositModal = false
      this.isShowWithdrawModal = false
      if(needReload) {
        this.loadAccount()
      }
    },
    changePassword() {
      this.changePasswordItem = {
        _id: this.data?.user._id,
        username: this.data?.user.username,
        nPassword: null,
        cPassword: null
      }
      this.isShowPasswordModal = true
    },
    editProfile() {
      this.editItem = {
        _id: this.data?.user._id,
        username: this.data?.user.username,
        firstName: this.data?.user.firstName,
        lastName: this.data?.user.lastName,
        tel: this.data?.user.tel,
        lineId: this.data?.user.lineId
      }
      this.isShowEditModal = true
    },
    blockAccount() {
      this.blockItem = {
        _id: this.data?.account._id,
        username: this.data?.user.username,
        blockNote: null
      }
      this.isShowBlockModal = true
    },
    deposit() {
      this.depositItem = {
        ...this.data?.account,
        users: [this.data?.user]
      }
      this.isShowDepositModal = true
    },
    withdraw() {
      this.withdrawItem = {
        ...this.data?.account,
        users: [this.data?.user]
      }
      this.isShowWithdrawModal = true
    }
  },
  mounted() {
    this.loadAccount()
  }
}
</script>
<style lang="scss">
.member-profile {
  .badge {
    font-size: 100%;
    font-weight: normal;
  }
}
</style>
