<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header header-tag="nav">
      <b-nav card-header tabs>
        <b-nav-item @click="activeTab='Transaction'" :active="activeTab==='Transaction'">รายงานการเงิน</b-nav-item>
        <b-nav-item @click="activeTab='Logs'" :active="activeTab==='Logs'">ประวัติการเข้าใช้งาน</b-nav-item>
      </b-nav>
    </b-card-header>

    <b-card-body class="p-2">
      <AccountFinanceReport v-if="activeTab==='Transaction'" :accountId="accountId" />
      <AccountLogReport v-if="activeTab==='Logs'" :accountId="accountId" />
    </b-card-body>
  </b-card>
</template>
<script>
import AccountFinanceReport from '@/views/account-report/components/AccountFinanceReport'
import AccountLogReport from '@/views/account-report/components/AccountLogReport'

export default {
  name: 'MemberReports',
  components: {
    AccountFinanceReport,
    AccountLogReport
  },
  computed: {
    accountId() {
      return this.$route.params?.accountId
    },
  },
  data() {
    return {
      activeTab: 'Transaction'
    }
  }
}
</script>
